

 function Headline() {

   return (
    <div className='z-40 relative mt-10  w-full flex items-center justify-center'>
         <h1 className="text-4xl lg:text-6xl sm:text-5xl text-pink-400 font-thin tracking-widest ">Alina Licht</h1>
    </div>
 )
} 
 


export default Headline